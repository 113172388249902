import { Cart, LineItem } from '@commerce/types/cart';
import { Product } from '@commerce/types/product';
import { CheckoutSection } from '@components/checkout/OnePageCheckout/Sections/enums/CheckoutSection';

const crypto = require('crypto');
const EXCLUDED_QUERY_PARAMS = ['piitoken', 'token', 'code'];
const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER as string;

export type GTagEvent = {
  // TODO replace these with enums once they're specified
  action: string;
  category: string;
  label?: string;
  amount?: number;
};

export enum GTagCommerceAction {
  ADD_TO_CART = 'add_to_cart',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_PAYMENT_INFO = 'add_payment_info',
  BEGIN_CHECKOUT = 'begin_checkout',
  CHECKOUT_PROGRESS = 'checkout_progress',
  INTERNAL_SEARCH = 'internal_search',
  LOGIN = 'login',
  LIFE360_OTP = 'life360_otp',
  PURCHASE = 'purchase',
  REFUND = 'refund',
  REMOVE_FROM_CART = 'remove_from_cart',
  SELECT_CONTENT = 'select_content',
  SET_CHECKOUT_OPTION = 'set_checkout_option',
  SIGN_UP = 'sign_up',
  VIEW_CART = 'view_cart',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_PROMOTION = 'view_promotion',
  VIEW_REFUND = 'view_refund',
  OPEN_SECTION = 'checkout_section_opened',
  SECTION_COMPLETED = 'checkout_section_completed',
  EXPRESS_PAYMENT_BUTTON_CLICKED = 'express_payment_button_clicked',
  REDIRECT_TO_AMAZON = 'redirect_to_amazon',
  SUBMIT_PHONE_NUMBER = 'submit_phone_number',
  OTP_PROMPT_LOAD = 'otp_prompt_load',
  OTP_SUCCESS = 'otp_success',
  OTP_ERROR = 'otp_error',
}

export type GTagCommerceEvent = {
  event: GTagCommerceAction;
  payload: any;
};

export type GAProduct = {
  productId?: string;
  quantity?: number;
  productName?: string;
  brand?: string;
  sku?: string;
  isCartUpsell?: boolean;
};

export const hashEmail = (email: string) => {
  return crypto.createHash('sha256').update(email).digest('base64');
};

export const pushToGTMDataLayer = (data: any): void => {
  if (GTM_TRACKING_ID) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }
};

const removeTokensFromQuery = (pagePath: URL | string): URL | string => {
  // pick up everything after the ?
  const query = pagePath.toString().split('?')[1];
  if (query) {
    const params = new URLSearchParams(query);
    for (const exclusion of EXCLUDED_QUERY_PARAMS) {
      if (params.has(exclusion)) {
        params.delete(exclusion);
      }
    }
    return `${(pagePath as string).split('?')[0]}?${encodeURI(params.toString())}`;
  }
  return pagePath;
};

export const pageView = (url: URL | string): void => {
  pushToGTMDataLayer({
    event: 'page_view',
    page: {
      url: removeTokensFromQuery(url),
      path: removeTokensFromQuery(url),
    },
  });
};

export const pushMissedBrowserLocaleToDataLayer = (browserLocale: string): void => {
  pushToGTMDataLayer({
    event: 'missed_browser_locale',
    browser_locale: browserLocale,
  });
};

export const pushCommerce = ({ event, payload }: GTagCommerceEvent): void => {
  pushToGTMDataLayer({
    event: event,
    ecommerce: payload,
  });
};

const formatLineItems = (lineItems: LineItem[] = []) => {
  return lineItems.map((item) => {
    return {
      item_id: item.variant.sku,
      item_name: item.variant.name,
      affiliation: 'Tile eCommerce',
      price: item.variant.price,
      quantity: item.quantity,
    };
  });
};

const getGtagCommercePayload = (cart: Cart) => ({
  currency: cart?.currency.code,
  coupon: cart?.coupons.map((coupon) => coupon.code).join(''),
  value: cart?.totalPrice,
  items: formatLineItems(cart?.lineItems),
});

export const pushAddShippingInfoEvent = (cart: Cart | null | undefined, shippingMethod: string) => {
  if (cart) {
    pushCommerce({
      event: GTagCommerceAction.ADD_SHIPPING_INFO,
      payload: {
        ...getGtagCommercePayload(cart),
        shipping_tier: shippingMethod,
      },
    });
  }
};

export const pushAddPaymentInfoEvent = (cart: Cart | null | undefined, paymentMethod: string) => {
  if (cart) {
    pushCommerce({
      event: GTagCommerceAction.ADD_PAYMENT_INFO,
      payload: {
        ...getGtagCommercePayload(cart),
        payment_type: paymentMethod,
      },
    });
  }
};

export const pushPaymentBtnClicked = (cart: Cart | null | undefined, paymentType: string) => {
  if (cart) {
    pushCommerce({
      event: GTagCommerceAction.EXPRESS_PAYMENT_BUTTON_CLICKED,
      payload: {
        ...getGtagCommercePayload(cart),
        express_payment_type: paymentType,
      },
    });
  }
};

export const pushSectionEvent = (
  cart: Cart | null | undefined,
  section: CheckoutSection,
  action: 'open' | 'completed'
) => {
  if (cart) {
    pushCommerce({
      event: action === 'completed' ? GTagCommerceAction.SECTION_COMPLETED : GTagCommerceAction.OPEN_SECTION,
      payload: {
        ...getGtagCommercePayload(cart),
        section,
      },
    });
  }
};

export const pushAmazonRedirectEvent = (sku: string, url: string): void => {
  if (url && sku) {
    pushCommerce({
      event: GTagCommerceAction.REDIRECT_TO_AMAZON,
      payload: {
        sku,
        url,
      },
    });
  }
};

export const pushAddToCartEvent = (product: GAProduct, cart: Cart | null | undefined): void => {
  if (product && cart) {
    const { productId, quantity = 1, productName, sku, isCartUpsell = false } = product;
    const pathName = window.location.pathname;
    const { lineItems, currency } = cart;
    const lineItemProduct = lineItems.find((item: any) => item.productId === productId);
    if (lineItemProduct) {
      pushCommerce({
        event: GTagCommerceAction.ADD_TO_CART,
        payload: {
          currency: currency.code,
          pathName,
          value: lineItemProduct.variant.price, // Calculate total value
          items: [
            {
              item_name: productName,
              item_id: sku,
              affiliation: 'Tile eCommerce',
              price: lineItemProduct.variant.price,
              quantity,
            },
          ],
          isCartUpsell,
          totalCartValue: cart?.subtotalPrice,
        },
      });
    }
  }
};

export const pushRemoveFromCartEvent = (product: GAProduct, cart: Cart | null | undefined): void => {
  if (product && cart) {
    const { productId, quantity = 1, productName, sku } = product;
    const { lineItems, currency } = cart;
    const lineItemProduct = lineItems.find((item: any) => item.productId === productId);
    if (lineItemProduct) {
      pushCommerce({
        event: GTagCommerceAction.REMOVE_FROM_CART,
        payload: {
          currency: currency.code,
          value: lineItemProduct.variant.price, // Calculate total value
          items: [
            {
              item_name: productName,
              item_id: sku,
              affiliation: 'Tile eCommerce',
              price: lineItemProduct.variant.price,
              quantity,
            },
          ],
        },
      });
    }
  }
};

export const pushUpdateCartEvent = (
  productId: string | null | undefined,
  cart: Cart | null | undefined,
  oldQuantity: number | null | undefined
): void => {
  if (productId && cart && oldQuantity) {
    const { lineItems } = cart;
    const lineItemProduct = lineItems.find((lineItem: any) => lineItem.productId === productId);
    if (lineItemProduct) {
      if (oldQuantity > lineItemProduct.quantity) {
        pushRemoveFromCartEvent(
          {
            productId,
            quantity: oldQuantity - lineItemProduct.quantity,
            productName: lineItemProduct.variant.name,
            sku: lineItemProduct.variant.sku,
          },
          cart
        );
      } else {
        pushAddToCartEvent(
          {
            productId,
            quantity: lineItemProduct.quantity - oldQuantity,
            productName: lineItemProduct.variant.name,
            sku: lineItemProduct.variant.sku,
          },
          cart
        );
      }
    }
  }
};

export const pushRemoveItemEntirelyFromCartEvent = (input: any): void => {
  if (input.productId && input.quantity && input.variant.sku) {
    pushCommerce({
      event: GTagCommerceAction.REMOVE_FROM_CART,
      payload: {
        currency: input.currency, // Default currency, adjust as needed
        value: input.variant.price, // Calculate total value
        items: [
          {
            item_name: input.variant.name,
            item_id: input.variant.sku,
            affiliation: 'Tile eCommerce',
            price: input.variant.price,
            quantity: input.quantity,
          },
        ],
      },
    });
  }
};

export const pushBeginCheckoutEvent = (cart: Cart | null | undefined): void => {
  if (cart) {
    pushCommerce({
      event: GTagCommerceAction.BEGIN_CHECKOUT,
      payload: getGtagCommercePayload(cart),
    });
  }
};

export const pushViewCartEvent = (cart: Cart | null | undefined) => {
  if (cart) {
    pushCommerce({
      event: GTagCommerceAction.VIEW_CART,
      payload: getGtagCommercePayload(cart),
    });
  }
};

export const pushViewItemEvent = (product: Product) => {
  pushCommerce({
    event: GTagCommerceAction.VIEW_ITEM,
    payload: {
      currency: product.price.currencyCode,
      value: product.price.value,
      items: [
        {
          item_id: product.sku,
          item_name: product.name,
          affiliation: 'Tile eCommerce',
          price: product.price.value,
          pack_size: product.customFields?.packSize ?? '',
          color: product.customFields?.color ?? '',
        },
      ],
    },
  });
};

export const pushLoginEvent = (userId: string, method: string = 'Tile Auth') => {
  pushCommerce({
    event: GTagCommerceAction.LOGIN,
    payload: {
      method,
      userId,
    },
  });
};

export const pushSignupEvent = (method: string = 'Tile Auth') => {
  pushCommerce({
    event: GTagCommerceAction.SIGN_UP,
    payload: {
      method,
    },
  });
};

export const pushOTPEvent = (eventName: string, payload: Record<string, unknown>) => {
  pushCommerce({
    event: GTagCommerceAction.LIFE360_OTP,
    payload: {
      event_name: eventName,
      ...payload,
    },
  });
};

export const setUserId = (userId: string): void => {
  pushToGTMDataLayer({ user_id: userId });
};

export const pushSearchEvent = (term: string): void => {
  pushToGTMDataLayer({
    event: GTagCommerceAction.INTERNAL_SEARCH,
    term: term,
  });
};
